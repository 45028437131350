var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-qa-info" },
    [
      _c("div", { staticClass: "page-body" }, [
        _c("div", { staticClass: "info-item header" }, [
          _c("span", [_vm._v("控制盒编号")]),
          _c("span", [_vm._v(_vm._s(_vm.$route.query.uniqueId))])
        ])
      ]),
      _vm._l(_vm.qaList, function(item, index) {
        return _c("div", { key: index, staticClass: "page-body" }, [
          item.type == 2
            ? _c("div", { staticClass: "info-item header" }, [
                _vm._v(_vm._s(index + 1) + "、功能质检")
              ])
            : _vm._e(),
          item.type == 3
            ? _c("div", { staticClass: "info-item header" }, [
                _vm._v(_vm._s(index + 1) + "、称重质检")
              ])
            : _vm._e(),
          _c("div", { staticClass: "info-item" }, [
            _c("span", [_vm._v("操作人")]),
            _c("span", [_vm._v(_vm._s(item.operatorName))])
          ]),
          _c("div", { staticClass: "info-item" }, [
            _c("span", [_vm._v("质检提交时间")]),
            _c("span", [_vm._v(_vm._s(item.submitAt))])
          ])
        ])
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
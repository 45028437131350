require("core-js/modules/es.array.concat");

require("core-js/modules/es.array.filter");

require("core-js/modules/es.array.map");

require("core-js/modules/es.regexp.exec");

require("core-js/modules/es.string.split");

(function (global, factory) {
  if (typeof define === "function" && define.amd) {
    define(["exports", "vant/es/toast/style", "vant/es/toast"], factory);
  } else if (typeof exports !== "undefined") {
    factory(exports, require("vant/es/toast/style"), require("vant/es/toast"));
  } else {
    var mod = {
      exports: {}
    };
    factory(mod.exports, global.style, global.toast);
    global.tlQaInfo = mod.exports;
  }
})(typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : this, function (_exports, _style, _toast) {
  "use strict";

  var _interopRequireDefault = require("/root/deploy/iot-product-tool/node_modules/@babel/runtime/helpers/interopRequireDefault");

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _toast = _interopRequireDefault(_toast);
  var _default = {
    name: 'TlQaInfo',
    data: function data() {
      return {
        qaList: []
      };
    },
    created: function created() {
      this.loadQaInfo();
    },
    methods: {
      // 获取设备检测状态
      loadQaInfo: function loadQaInfo() {
        var vm = this;

        var tt = _toast.default.loading({
          message: "加载中...",
          forbidClick: true,
          overlay: true,
          duration: 0
        });

        vm.$http({
          type: "get",
          url: "".concat(vm.$config.base.PROBE_BASE_URL, "tl/qcList?uniqueId=").concat(vm.$route.query.uniqueId)
        }).then(function (res) {
          var _ref = res.data || [],
              data = _ref.data;

          console.log(res);
          tt.clear();
          data.length && data.map(function (item) {
            var s = item.submitAt.split('T')[1].split(':');
            item.submitAt = "".concat(item.submitAt.split('T')[0], " ").concat(s[0], ":").concat(s[1]);
          });
          vm.qaList = data.filter(function (item) {
            return item.type > 1;
          });
          console.log(vm.qaList);
        }).catch(function () {
          tt.clear();
        });
      }
    }
  };
  _exports.default = _default;
});